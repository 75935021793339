import { Icon } from '/features/buildingBlocks/Icon'

import styles from './Tag.css'

export function Tag({ label, colorScheme, layoutClassName = undefined }) {
  return (
    <TagBase className={cx(styles.component, layoutClassName)} {...{ colorScheme }}>
      {label}
    </TagBase>
  )
}

export function TagWithIcon({ label, icon, colorScheme, layoutClassName = undefined }) {
  return (
    <TagBase className={cx(styles.componentWithIcon, layoutClassName)} {...{ colorScheme }}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
      {label}
    </TagBase>
  )
}

export function TagWithLogo({ icon, colorScheme, layoutClassName = undefined }) {
  return (
    <TagBase className={cx(styles.componentWithLogo, layoutClassName)} {...{ colorScheme }}>
      <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
    </TagBase>
  )
}

function TagBase({ children, colorScheme, className }) {
  const { colorClass } = getTagTheme({ colorScheme })

  return (
    <div className={cx(styles.componentBase, colorClass, className)}>
      {children}
    </div>
  )
}

function getTagTheme({ colorScheme }) {
  switch (colorScheme) {
    case 'white': return { colorClass: styles.tagWhiteBackground }
    case 'blue': return { colorClass: styles.tagBlueBackground }
    case 'darkBlue': return { colorClass: styles.tagDarkBlueBackground }
    case 'default': return { colorClass: styles.tagDefaultBackground }
    default: throw new Error('Invalid colorScheme passed in Tag component. Use one of the following: blue, darkBlue, white or default')
  }
}
