import { animated } from '@react-spring/web'
import { useRenderOnMount } from '@kaliber/use-render-on-mount'

import { useParallax } from '/machinery/useParallax'

import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './MediaWindow.css'

export function MediaWindow({ image, aspectRatio, distanceOverride = undefined, layoutClassName = undefined }) {
  const distance = distanceOverride ? distanceOverride : 150
  const isMounted = useRenderOnMount()
  const { parallaxRef, parallaxStyle } = useParallax({ distance, springConfig: {  tension: 500, friction: 35 } })

  return isMounted && (
    <div ref={parallaxRef} style={{ '--aspect-ratio': aspectRatio }} className={cx(styles.component, layoutClassName)}>
      <animated.div className={styles.imageWrap} style={parallaxStyle}>
        <ImageCover layoutClassName={styles.imageLayout} {...{ image, aspectRatio }} />
      </animated.div>
    </div>
  )
}
