import { useGetBoundingClientRect } from '/machinery/useGetBoundingClientRect'

import { EnterAnimation } from '/features/buildingBlocks/EnterAnimation'
import { HeadingMd } from '/features/buildingBlocks/Heading'
import { TagsWrapper } from '/features/buildingBlocks/TagsWrapper'
import { Tag } from '/features/buildingBlocks/Tag'
import { MediaWindow } from '/features/pageOnly/MediaWindow'
import { LinePageHero } from '/features/pageOnly/Line'

import styles from './HeroDocument.css'

export function HeroDocumentBlue({ title, heroImage, secondaryImage, jobAreas = [], type = undefined, layoutClassName = undefined }) {
  return (
    <HeroDocumentBase
      tagColor='darkBlue'
      className={cx(styles.componentBlue, layoutClassName)}
      {...{ title, heroImage, secondaryImage, type, jobAreas }}
    />
  )
}

export function HeroDocumentLightBlue({ title, heroImage, secondaryImage, jobAreas, type = undefined, layoutClassName = undefined }) {
  return (
    <HeroDocumentBase
      tagColor='blue'
      className={cx(styles.componentLightBlue, layoutClassName)}
      {...{ title, heroImage, secondaryImage, type, jobAreas }}
    />
  )
}

export function HeroDocumentDarkBlue({ title, heroImage, secondaryImage, jobAreas = [], type = undefined, layoutClassName = undefined }) {
  return (
    <HeroDocumentBase
      tagColor='blue'
      className={cx(styles.componentDarkBlue, layoutClassName)}
      {...{ title, heroImage, secondaryImage, type, jobAreas }}
    />
  )
}

function HeroDocumentBase({ title, heroImage, secondaryImage, jobAreas, tagColor, type = undefined, className }) {
  const hasHeroImages = heroImage?.asset && secondaryImage?.asset

  return (
    <section data-x='hero-document' className={cx(styles.componentBase, className)}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          {hasHeroImages && <Images layoutClassName={styles.imagesLayout} {...{ heroImage, secondaryImage }} />}
          <Text layoutClassName={styles.textLayout} {...{ title, type, jobAreas, tagColor }} />
        </div>
      </div>
    </section>
  )
}

function Text({ title, jobAreas, tagColor, type = undefined, layoutClassName = undefined }) {
  const { height: elementHeight, elementRef } = useGetBoundingClientRect()
  const { height: headingHeight, elementRef: headingRef } = useGetBoundingClientRect()
  const lineHeight = elementHeight - (headingHeight / 2)

  return (
    <div ref={elementRef} className={cx(styles.componentText, layoutClassName)}>
      <LinePageHero {...{ lineHeight }} />

      <div className={styles.textContentContainer}>
        <div ref={headingRef} className={styles.headingContainer}>
          <EnterAnimation offset={0}>
            <HeadingMd h='1'>{title}</HeadingMd>
          </EnterAnimation>
        </div>

        {(type || Boolean(jobAreas.length)) && (
          <EnterAnimation delay={250} offset={0}>
            <TagsWrapper>
              {type && <Tag label={type} colorScheme={tagColor} />}
              {Boolean(jobAreas.length) && jobAreas.map(jobArea => <Tag key={jobArea} label={jobArea} colorScheme={tagColor} />)}
            </TagsWrapper>
          </EnterAnimation>
        )}
      </div>

    </div>
  )
}

function Images({ heroImage, secondaryImage, layoutClassName = undefined }) {
  return (
    <div className={cx(styles.componentImages, layoutClassName)}>
      <div className={styles.imageContainer}>
        <EnterAnimation offset={0}>
          <MediaWindow image={heroImage} aspectRatio={2 / 3} distanceOverride={50} />
        </EnterAnimation>
      </div>
      <div className={styles.imageContainer}>
        <EnterAnimation offset={0}>
          <MediaWindow image={secondaryImage} aspectRatio={2 / 3} distanceOverride={65} />
        </EnterAnimation>
      </div>
    </div>
  )
}
