export function useGetBoundingClientRect() {
  const elementRef = React.useRef(null)
  const [boundingRect, setBoundingRect] = React.useState({ x: 0, y: 0, top: 0, bottom: 0, width: 0, height: 0 })
  const { x, y, top, bottom, width, height } = boundingRect

  React.useEffect(
    () => {
      handleResize()
      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)

      function handleResize() {
        setBoundingRect(elementRef.current.getBoundingClientRect())
      }
    },
    []
  )

  return { x, y, top, bottom, width, height, elementRef }
}
