import { useSpring } from '@react-spring/web'
import { useScrollProgression, triggers }  from '@kaliber/scroll-progression'
import { lerp } from '@kaliber/math'

export function useParallax({ distance, springConfig }) {
  const [parallaxStyle, spring] = useSpring(() => ({
    y: 0,
    height: `calc(100% + ${distance * 1.5}px)`, inset: -distance + 'px 0px',
    config: { ...springConfig }
  }))

  const parallaxRef = useScrollProgression({
    start: { element: triggers.top(), scrollParent: triggers.bottom() },
    end: { element: triggers.bottom(), scrollParent: triggers.top() },
    onChange(progression) {
      spring.start({ y: lerp({ start: -distance, end: distance, input: progression }) })
    }
  })

  return {
    parallaxRef,
    parallaxStyle
  }
}
