import { useSpring, animated } from '@react-spring/web'
import { useWasInViewport } from '@kaliber/use-is-in-viewport'

export function EnterAnimation({ children, delay = undefined, offset = undefined, layoutClassName = undefined }) {
  const { ref, wasInViewport } = useWasInViewport({ rootMargin: `${(offset ?? -0.25) * 100}%` })

  const style = useSpring({
    delay,
    opacity: wasInViewport ? 1 : 0,
    y: wasInViewport ? 0 : 50,
    config: { mass: 2, tension: 250, friction: 75 },
  })

  return (
    <animated.div className={layoutClassName} {...{ ref, style }}>
      {children}
    </animated.div>
  )
}
